import React, { forwardRef, useImperativeHandle } from 'react';
import { ValidateHandle } from './ValidateHandle';
import { DropDown, Label } from '@zaiusinc/hera';
import Notification from '../Notification';
import { ContentGraphConfig } from '../../../../types/DataSyncItem';
import { cgLanguages, cgStatuses } from '../../../../types/ContentGraph';

interface ContentGraphConfigSectionProps {
  disabled?: boolean;
  onChange: (value: ContentGraphConfig) => void;
  initialValue?: ContentGraphConfig;
}

const ContentGraphConfigSection = forwardRef<ValidateHandle, ContentGraphConfigSectionProps>(
  (props, ref) => {

    const {disabled, initialValue, onChange} = props;
    const [cgConfig, setCGConfig] = React.useState<ContentGraphConfig>(
      {status: initialValue?.status || 'DRAFT', language: initialValue?.language || 'CG_LANGUAGE_NEUTRAL'}
    );
    const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
      language: false,
      status: false,
    });

    useImperativeHandle(ref, () => ({
      validate: () => {
        setErrors({
          language: !cgConfig.language,
          status: !cgConfig.status,
        });
        return !!(cgConfig.language && cgConfig.status);
      },
    }));

    return (
      <>
        <Label label="Status" disabled={disabled}>
          <DropDown
            disabled={disabled}
            initialContent="Select Status"
            items={cgStatuses}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...cgConfig, status: item.value};
              setCGConfig(newData);
              onChange(newData);
              setErrors({...errors, status: false});
            }}
            value={cgConfig.status}
            width={390}/>
          <Notification message="Please select a status fro Content Graph Object" show={errors.status} type="error" />
        </Label>

        <Label label="Language" disabled={disabled}>
          <DropDown
            filterable
            disabled={disabled}
            initialContent="Select Language"
            items={cgLanguages}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...cgConfig, language: item.value};
              setCGConfig(newData);
              onChange(newData);
              setErrors({...errors, language: false});
            }}
            value={cgConfig.language}
            width={390}/>
          <Notification
            show={errors.language} type="error"
            message="Please select a language for Content Graph Object"
          />
        </Label>
      </>
    );
  });

export default ContentGraphConfigSection;
