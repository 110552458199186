import React, { forwardRef, useImperativeHandle } from 'react';
import styles from './DataSyncFormSection.module.sass';
import { DropDown, Label } from '@zaiusinc/hera';
import Notification from '../Notification';
import { ValidateHandle } from './ValidateHandle';
import { dataSyncScheduleIntervals } from '../../../../types/DataSyncItem';

export interface DataSyncScheduleSectionProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const DataSyncScheduleSection = forwardRef<ValidateHandle, DataSyncScheduleSectionProps>((props, ref) => {
  const {value, onChange, disabled} = props;
  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
    interval: false,
  });

  useImperativeHandle(ref, () => ({
    validate: () => {
      setErrors({...errors, interval: !value});
      return !!value;
    },
  }));

  return (
    <div className={styles.fullWidthContainer}>
      <Label label="Sync Schedule" subLabel="Set the frequency for this sync" disabled={disabled}>
        <DropDown
          disabled={disabled}
          initialContent="Select Interval"
          items={dataSyncScheduleIntervals}
          value={value} width={390}
          onItemSelect={function noRefCheck(item: any) {
            onChange(item.value);
            setErrors({...errors, interval: false});
          }}/>
        <Notification message={'Please select an interval'} show={errors.interval} type="error"/>
      </Label>
    </div>
  );
});

export default DataSyncScheduleSection;
