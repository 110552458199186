import { observable } from 'mobx';
import { DataSyncExecution } from './DataSyncExecution';

export enum OptimizelyProduct {
  ODP = 'ODP',
  CGR = 'CGR',
  CMS = 'CMS',
  CMP = 'CMP',
  EXP = 'EXP'
}

export interface ProductInstance {
  id: string;
  name: string;
}

export interface ProductInstances {
  product: OptimizelyProduct;
  instances: ProductInstance[];
}

export interface ObjectDefinition {
  object_name: string;
  object_display_name: string;
  object_icon_url: string;
  fields: ObjectFieldDefinition[];
}

export interface ObjectFieldDefinition {
  name: string;
  display_name: string;
  type: string;
}

export interface DataSyncSource {
  object_name: string;
  tracker_id?: string;
  object_display_name?: string;
  object_icon_url?: string;
}

export interface DataSyncDestination {
  object_name: string;
  product: OptimizelyProduct;
  instance: ProductInstance;
  config: DestinationConfig;
}

export interface Mappings {
  [key: string]: {
    source_field: string;
  };
}

export interface FieldMappings {
  mappings: Mappings;
}

export interface DestinationConfig {
  content_graph?: ContentGraphConfig;
}

export interface ContentGraphConfig {
  language: string;
  status: string;
}

export const dataSyncScheduleIntervals = [
  {text: 'Every 10 minutes', value: '600s'},
  {text: 'Every 20 minutes', value: '1200s'},
  {text: 'Every 30 minutes', value: '1800s'},
  {text: 'Every 1 hour', value: '3600s'},
  {text: 'Every 2 hours', value: '7200s'},
  {text: 'Every 3 hours', value: '10800s'},
  {text: 'Every 4 hours', value: '14400s'},
  {text: 'Every 6 hours', value: '21600s'},
  {text: 'Every 12 hours', value: '43200s'},
  {text: 'Every 1 day', value: '86400s'},
  {text: 'Every 2 days', value: '172800s'}
];

export class DataSyncItem {

  public static createEmpty(): DataSyncItem {
    return new DataSyncItem({
      id: undefined,
      name: '',
      enabled: false,
      source: {
        object_name: '',
        tracker_id: ''
      },
      destination: {
        object_name: '',
        product: '',
        instance: {
          id: '',
          name: ''
        }
      },
      field_mappings: {}
    });
  }
  @observable public id: string;
  @observable public name: string;
  @observable public interval: string;
  @observable public source: DataSyncSource;
  @observable public destination: DataSyncDestination;
  @observable public enabled: boolean;
  @observable public field_mappings: FieldMappings;
  @observable public lastExecution?: DataSyncExecution;

  constructor(obj: any) {
    this.id = obj.id;
    this.name = obj.name;
    this.interval = obj.interval;
    this.enabled = obj.enabled;
    this.source = obj.source;
    this.destination = obj.destination;
    this.field_mappings = obj.field_mappings;
    this.lastExecution = obj.lastExecution;
  }
}
