import { action, observable, reaction } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { SelectItem } from '@zaiusinc/hera';
import { ObjectDefinition, ObjectFieldDefinition } from '../types/DataSyncItem';

export default class DataSyncCGStore {
  @observable public loading = false;
  @observable public instanceId: string | null = null;

  @observable private _objectDefinitions: ObjectDefinition[] = [];

  public constructor() {
    reaction(() => directoryStore.trackerId, () => {
      if (directoryStore.trackerId) {
        this.fetch();
      }
    }, {fireImmediately: true});
  }

  @action
  public async fetch() {
    try {
      this.loading = true;
      this._objectDefinitions = [];
      if (directoryStore.trackerId && this.instanceId) {
        this._objectDefinitions = await ZipApi.listCGSchemas(directoryStore.trackerId, this.instanceId);
      }
    } finally {
      this.loading = false;
    }
  }

  @action
  public setInstanceId(instanceId: string) {
    this.instanceId = instanceId;
    this.fetch();
  }

  public getObjectList(): SelectItem[] {
    return this._objectDefinitions.map((object) => {
      return {
        text: object.object_name,
        value: object.object_name,
      };
    });
  }

  public getObjectFields(objectName: string): ObjectFieldDefinition[] {
    const object = this._objectDefinitions.find((obj) => obj.object_name === objectName);
    return object?.fields || [];
  }
}
