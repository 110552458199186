import React, { useEffect, useState } from 'react';
import DataSyncExecutionList from './components/DataSyncExecutionList';
import { useHistory, useParams } from 'react-router-dom';
import DataSyncDetailsStore from '../../stores/DataSyncDetailsStore';
import { showToasts } from '../../lib/toaster';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { useObserver } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';
import MainContainer from './MainContainer';

export const DataSyncExecutionsPage = () => {
  const {dataSyncId} = useParams<{ dataSyncId: string }>();
  const [dataSyncDetailsStore, setDataSyncDetailsStore] = useState<DataSyncDetailsStore | null>(null);
  const history = useHistory();

  useEffect(() => {
    const store = new DataSyncDetailsStore(dataSyncId);
    setDataSyncDetailsStore(store);
    store.getExecutions()
      .catch((e) => {
        showToasts([{intent: 'danger', message: `Failed to fetch data sync executions. ${e}`}]);
      });
  }, [dataSyncId]);

  return useObserver(() => {
    const isReady = dataSyncDetailsStore !== null;

    const closeButton = (
      <Button
        text="Close"
        intent="primary"
        onClick={() => {
          history.push('/data_syncs');
        }}
      />);

    return (
      <MainContainer title="Executions List" headerActions={closeButton}>
        {isReady ? <DataSyncExecutionList detailStore={dataSyncDetailsStore}/> : <LoadingIndicator/>}
      </MainContainer>
    );
  });
};
