import { observable, reaction, action } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { ObjectDefinition, ObjectFieldDefinition } from '../types/DataSyncItem';

export default class DataSyncOdpObjectsStore {
  @observable public loading = false;

  @observable private _objectDefinitions: ObjectDefinition[] = [];

  public constructor() {
    reaction(() => directoryStore.trackerId, () => {
      if (directoryStore.trackerId) {
        this.fetch();
      }
    }, {fireImmediately: true});
  }

  @action
  public async fetch() {
    try {
      this.loading = true;
      this._objectDefinitions = [];

      if (directoryStore.trackerId) {
        const tmp = await ZipApi.listODPSchemas(directoryStore.trackerId);
        tmp.sort((a, b) => a.object_display_name.localeCompare(b.object_display_name));
        this._objectDefinitions = tmp;
      }
    } finally {
      this.loading = false;
    }
  }

  public getObjectList(): ObjectDefinition[] {
    return this._objectDefinitions;
  }

  public getObjectFieldList(objectName: string): ObjectFieldDefinition[] {
    const object = this._objectDefinitions.find((obj) => obj.object_name === objectName);
    if (!object) {
      return [];
    }
    return object.fields;
  }
}
