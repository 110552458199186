import React from 'react';
import styles from './Notification.module.sass';

interface NotificationProps {
  message: string;
  show: boolean;
  type: 'error' | 'warning';
}

const Notification: React.FC<NotificationProps> = ({message, show, type}) => {
  if (!show) {
    return null;
  }

  const typeClass = type === 'error' ? styles.error : type === 'warning' ? styles.warning : '';
  return <div className={`${styles.container} ${typeClass}`}>{message}</div>;
};

export default Notification;
