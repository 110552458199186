import {Icon, Intent} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import classNames from 'classnames';
import {useObserver} from 'mobx-react-lite';
import * as React from 'react';
import {appListStore} from '../../stores/AppListStore';
import {directoryStore} from '../../stores/DirectoryStore';
import styles from './CategoryFilters.module.sass';
import {SegmentAnalytics} from '../../lib/SegmentAnalytics';

export const CategoryFilters = () => {
  return useObserver(() => (
    <div className={styles.container}>
      <h6>Categories</h6>
      {directoryStore.trackerId &&
        <>
          <div
            className={classNames(styles.listItem, {[styles.selected]: appListStore.filter.installed})}
            onClick={onInstalledClick}
          >
            Installed&#20;
            <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} />
          </div>
          <div className={styles.divider}/>
        </>
      }
      <div
        className={classNames(styles.listItem, {[styles.selected]: !appListStore.hasFilters()})}
        onClick={clearFilters}
      >
        All
      </div>
      {appListStore.categories.map((category) => (
        <div
          className={classNames(styles.listItem, {[styles.selected]: appListStore.filter.category === category})}
          onClick={() => onCategoryClick(category)}
          key={category}
        >
          {category}
        </div>
      ))}
    </div>
  ));
};

function clearFilters() {
  appListStore.clearFilters();
}

function onCategoryClick(category: string) {
  if (appListStore.filter.category === category) {
    appListStore.clearFilters();
  } else {
    appListStore.setFilter({category});
    SegmentAnalytics.track(`Filter category: ${category}`, 'App list');
  }
}

function onInstalledClick() {
  if (appListStore.filter.installed) {
    appListStore.clearFilters();
  } else {
    appListStore.setFilter({installed: true});
    SegmentAnalytics.track(`Filter category: Installed`, 'App list');
  }
}
