import React, { useEffect, useState } from 'react';
import styles from './MappingRow.module.sass';
import { Button, Icon } from '@blueprintjs/core';
import { DropDown } from '@zaiusinc/hera';
import { IconNames, IconSize } from '@blueprintjs/icons';
import { MenuItem } from '@blueprintjs/core';
import { ItemRenderer } from '@blueprintjs/select';
import { ObjectFieldDefinition } from '../../../../types/DataSyncItem';
import { SelectItem } from '@zaiusinc/hera';

export interface SrcDestPair {
  source: string | null;
  destination: string | null;
}

interface AddNewMappingRowProps {
  initialValues: SrcDestPair;
  sourceFields: ObjectFieldDefinition[];
  destinationFields: ObjectFieldDefinition[];
  onChange: (value: SrcDestPair) => void;
  onDelete: () => void;
  disabled: boolean;
}

const MappingRow = ({
                      initialValues,
                      sourceFields,
                      destinationFields,
                      onChange,
                      onDelete,
                      disabled
                    }: AddNewMappingRowProps) => {
  const [inputRow, setInputRow] = useState<SrcDestPair>(initialValues);
  const [fieldsLoaded, setFieldsLoaded] = useState({
    sourceFields: false,
    destinationFields: false,
  });

  useEffect(() => {
    // Mark source fields as loaded when `sourceFields` is populated
    if (sourceFields.length > 0 && !fieldsLoaded.sourceFields) {
      setFieldsLoaded((prev) => ({...prev, sourceFields: true}));
    }
  }, [sourceFields]);

  useEffect(() => {
    // Mark destination fields as loaded when `destinationFields` is populated
    if (destinationFields.length > 0 && !fieldsLoaded.destinationFields) {
      setFieldsLoaded((prev) => ({...prev, destinationFields: true}));
    }
  }, [destinationFields]);

  useEffect(() => {
    if (fieldsLoaded.destinationFields && inputRow.destination) {
      // Check if the current destination value exists in the fields
      const destinationExists = destinationFields.some((item) => item.name === inputRow.destination);
      if (!destinationExists) {
        setDestinationValue(null);
      }
    }
  }, [destinationFields, fieldsLoaded.destinationFields]);

  useEffect(() => {
    if (fieldsLoaded.sourceFields && inputRow.source) {
      // Check if the current source value exists in the fields
      const sourceExists = sourceFields.some((item) => item.name === inputRow.source);
      if (!sourceExists) {
        setSourceValue(null);
      }
    }
  }, [sourceFields, fieldsLoaded.sourceFields]);

  const setSourceValue = (value: string| null) => {
    const tmp = {...inputRow, source: value};
    setInputRow(tmp);
    onChange(tmp);
  };

  const setDestinationValue = (value: string | null) => {
    const tmp = {...inputRow, destination: value};
    setInputRow(tmp);
    onChange(tmp);
  };

  const convertToItems = (fields: ObjectFieldDefinition[]): SelectItem[] => {
    return fields.map((field) => ({
      text: field.display_name || field.name,
      value: field.name,
      type: field.type,
    }));
  };

  const itemRenderer: ItemRenderer<SelectItem> = (item, {handleClick, handleFocus, modifiers, query}) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        key={item.value}
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={item.type?.toLowerCase() || 'n/a'}
        onClick={handleClick}
        onFocus={handleFocus}
        text={item.text}
      />
    );
  };

  return (
    <div className={styles.row}>
      <div className={styles.source}>
        <DropDown
          filterable
          disabled={disabled}
          width={390}
          initialContent="Select source"
          items={convertToItems(sourceFields)}
          itemRenderer={itemRenderer}
          onItemSelect={(e) => setSourceValue(e.value)}
          value={inputRow.source}
        />
      </div>
      <Icon className={`${styles.icon} ${disabled && styles.iconDisabled}`} icon={IconNames.ARROW_RIGHT}/>
      <div className={styles.destination}>
        <DropDown
          filterable
          disabled={disabled}
          width={350}
          initialContent="Select destination"
          items={convertToItems(destinationFields)}
          itemRenderer={itemRenderer}
          onItemSelect={(e) => setDestinationValue(e.value)}
          value={inputRow.destination}
        />
        <Button onClick={onDelete} disabled={disabled}>
          <Icon className={styles.icon} icon={IconNames.CROSS} size={IconSize.LARGE}/>
        </Button>
      </div>
    </div>
  );
};

export default MappingRow;
