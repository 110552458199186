import {AnalyticsBrowser} from '@segment/analytics-next';
import {directoryStore} from '../stores/DirectoryStore';

export type FeatureName = 'App list' | 'Overview' | 'Settings' | 'Troubleshoot';

export class SegmentAnalytics {

  public static async identify() {
    if (directoryStore.accountDetails) {
      const {
        userEmail: userId,
        userName: name,
        groupId,
        organizationId,
        instanceId,
        instanceName,
        odpAccountId,
        isOptiUser,
      } = directoryStore.accountDetails;

      await this.analytics.identify(userId, {
        groupId,
        organizationId,
        instanceId,
        instanceName,
        name,
        isOptiUser,
        odpAccountId,
        productName: this.productName,
        subProductName: this.productSubName,
      });

      await this.analytics.group(groupId, {
        organizationId,
        instanceId,
        instanceName,
        hasTurnstile: !!organizationId,
        name,
        odpAccountId
      });
    }
  }

  public static async page(pathname: string) {
    if (directoryStore.accountDetails) {
      const {userEmail: userId, groupId} = directoryStore.accountDetails;

      await this.analytics.page(`App directory page: ${pathname}`, {
        userId,
        groupId,
        productName: this.productName,
        subProductName: this.productSubName,
      });
    }
  }

  public static async track(message: string, featureName: FeatureName, properties?: Record<string, any>) {
    if (directoryStore.accountDetails) {
      const {
        userEmail: userId,
        userName: name,
        groupId,
        organizationId,
        instanceId,
        instanceName,
        odpAccountId,
        isOptiUser,
      } = directoryStore.accountDetails;

      await this.analytics.track(message, {
        userId,
        name,
        groupId,
        organizationId,
        instanceId,
        instanceName,
        isOptiUser,
        odpAccountId,
        productName: this.productName,
        subProductName: this.productSubName,
        featureName,
        ...properties,
      });
    }
  }
  private static readonly productName = 'ODP';
  private static readonly productSubName = 'App Directory';

  private static analytics: AnalyticsBrowser =
    AnalyticsBrowser.load({writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY!});
}
