import React from 'react';
import { DataSyncExecution } from '../../../types/DataSyncExecution';
import styles from './DataSyncExecutionList.module.sass';
import { Icon, Card, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { DataTablePaging, DataTablePagingProps } from '@zaiusinc/hera';
import { useObserver } from 'mobx-react-lite';
import DataSyncDetailsStore from '../../../stores/DataSyncDetailsStore';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { Tag } from '@blueprintjs/core';
import DataSyncIcons from './DataSyncIcons';

interface DataSyncExecutionListProps {
  detailStore: DataSyncDetailsStore;
}

const statusIntentMap = {
  COMPLETED: Intent.SUCCESS,
  FAILED: Intent.DANGER,
};

const statusStringMap = {
  SYNC_STATUS_NOT_SET: 'Not Set',
  PENDING: 'Pending',
  EXPORT_REQUESTED: 'Export Requested',
  EXPORT_READY: 'Export Ready',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
};

const buildStatusTag = (status: string) => {
  const intent = statusIntentMap[status] || Intent.NONE;

  return (
    <Tag round={true} intent={intent} className={styles.cardStatusTag}>
      {statusStringMap[status]}
    </Tag>
  );
};

const formatDateTime = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };
  return (new Date(date).toLocaleString('en-US', options)).toString();
};

const buildStats = (execution: DataSyncExecution) => {
  if (execution.exportCompletionTime === undefined &&
    execution.importCompletionTime === undefined &&
    execution.itemsImported === undefined &&
    execution.itemsDropped === undefined) {
    return <div>No stats yet</div>;
  }

  return (
    <div className={styles.executionDetails}>
      {execution.exportCompletionTime !== undefined && (
        <div className={styles.detailItem}>
          Export duration: <b>{execution.exportCompletionTime}</b></div>
      )}
      {execution.importCompletionTime !== undefined && (
        <div className={styles.detailItem}>
          Import duration: <b>{execution.importCompletionTime}</b></div>
      )}
      {execution.itemsImported !== undefined && (
        <div className={styles.detailItem}>
          Items imported: <b>{execution.itemsImported}</b></div>
      )}
      {execution.itemsDropped !== undefined && (
        <div className={styles.detailItem}>
          Items dropped: <b>{execution.itemsDropped}</b></div>
      )}
    </div>
  );
};

const DataSyncExecutionList: React.FC<DataSyncExecutionListProps> = ({detailStore}) => {
  return useObserver(() => {
    const {executions, loading, dataSync} = detailStore;

    const renderEmptyState = () => (
      <div className={styles.emptyList}>
        <Icon icon={IconNames.SEARCH} iconSize={64}/>
        <p>No executions were found</p>
      </div>
    );

    const renderExecutionCard = (execution: DataSyncExecution) => {
      const createdAt = new Date(execution.created_at);

      return (
        <Card interactive={false} key={execution.id} className={styles.card}>
          <div className={styles.cardRow}>

            <div className={styles.cardDateTime}>
              Created on {formatDateTime(createdAt)}
            </div>

            <div>
              {buildStatusTag(execution.status)}
            </div>

            <div className={styles.cardStats}>
              <Icon icon={IconNames.TIME}/>
              {buildStats(execution)}
            </div>
          </div>

          {execution.message && <div className={styles.cardErrorMessage}>
            Error Message: {execution.message}
          </div>}

        </Card>
      );
    };

    if (loading) {
      return <LoadingIndicator/>;
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            <DataSyncIcons dataSync={dataSync} />
            <div>{dataSync?.name}</div>
          </div>
          <div className={styles.pagination}>
            <DataTablePaging
              rowStart={detailStore.executionsPaging.rowStart}
              rowCount={detailStore.executionsPaging.rowCount}
              total={detailStore.executionsPaging.total}
              onPaging={async (pagingProps: DataTablePagingProps) => {
                await detailStore.setExecutionsPaging(pagingProps);
              }}
              hideDropDown={false}
            />
          </div>
        </div>
        {executions.length === 0 ? renderEmptyState() : executions.map(renderExecutionCard)}
      </div>
    );
  });
};

export default DataSyncExecutionList;
