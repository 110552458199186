import { SelectItem } from '@zaiusinc/hera';
import { observable, reaction, action } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { ProductInstances } from '../types/DataSyncItem';

export default class DataSyncProductsStore {
  @observable public loading = false;

  @observable private _productInstances: ProductInstances[] = [];

  private PRODUCTS_MAP = {
    'CGR': {
      name: 'Content Graph',
      icon: '/assets/content_graph.svg'
    }
  };

  public constructor() {
    reaction(() => directoryStore.trackerId, () => {
      if (directoryStore.trackerId) {
        this.fetch();
      }
    }, {fireImmediately: true});
  }

  @action
  public async fetch() {
    try {
      this.loading = true;
      this._productInstances = [];
      if (directoryStore.trackerId) {
        this._productInstances = await ZipApi.listProductInstances(directoryStore.trackerId);
      }
    } finally {
      this.loading = false;
    }
  }

  public getProductList(): Array<{ text: string; value: string; iconPath: string }> {
    return this._productInstances.map((product) => {
      return {
        text: this.PRODUCTS_MAP[product.product].name || product.product,
        value: product.product,
        iconPath: this.PRODUCTS_MAP[product.product].icon || '',
      };
    });
  }

  public getInstanceList(productName: string): SelectItem[] {
    const product = this._productInstances.find((obj) => obj.product === productName);
    if (!product) {
      return [];
    }
    return product.instances.map((instance) => {
      return {
        text: instance.name,
        value: instance.id,
      };
    });
  }
}
