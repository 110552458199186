import React, { forwardRef, useImperativeHandle } from 'react';
import styles from './DataSyncFormSection.module.sass';
import { DropDown, Label, SelectItem } from '@zaiusinc/hera';
import DataSyncOdpObjectsStore from '../../../../stores/DataSyncOdpObjectsStore';
import { DataSyncSource } from '../../../../types/DataSyncItem';
import { directoryStore } from '../../../../stores/DirectoryStore';
import { useObserver } from 'mobx-react-lite';
import Notification from '../Notification';
import { ValidateHandle } from './ValidateHandle';

export interface DataSyncFormSourceProps {
  initialValue: DataSyncSource;
  onChange: (value: DataSyncSource) => void;
  dataSyncOdpObjectsStore: DataSyncOdpObjectsStore | null;
}

const DataSyncFormSource = forwardRef<ValidateHandle, DataSyncFormSourceProps>((props, ref) => {
  const {initialValue, onChange, dataSyncOdpObjectsStore} = props;
  const [source, setSource] = React.useState<DataSyncSource>({...initialValue});
  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
    object_name: false,
  });

  useImperativeHandle(ref, () => ({
    validate: () => {
      setErrors({...errors, object_name: !source.object_name});
      return !!source.object_name;
    },
  }));

  const objectList = (): SelectItem[] => {
    const items = dataSyncOdpObjectsStore?.getObjectList();
    if (!items) {
      return [];
    }

    return items.map((object) => {
      const icon =
        object.object_icon_url ?
          (<img height={16} src={object.object_icon_url} alt={object.object_display_name}/>) :
          (<img height={30} width={30} src={'/assets/data_platform.svg'} alt={object.object_display_name}/>);

      return {
        text: object.object_display_name,
        value: object.object_name,
        icon,
      };
    });
  };

  return useObserver(() => (
    <div className={styles.container}>
      <Label
        label="Source"
        subLabel="Select an external app as the data source. If your app isn’t listed,
          check the App Directory for available data integrations.">
        <></>
      </Label>

      <Label label="Object">
        <DropDown
          filterable
          loading={dataSyncOdpObjectsStore?.loading || false}
          initialContent="Select Object"
          value={source.object_name}
          items={objectList()}
          width={390}
          onItemSelect={(item) => {
            const newSource = {...source, object_name: item.value, tracker_id: directoryStore.trackerId};
            setSource(newSource);
            onChange(newSource);
            setErrors({...errors, object_name: false});
          }}/>
        <Notification message="Please select an object" show={errors.object_name} type="error"/>
      </Label>

    </div>
  ));
});

export default DataSyncFormSource;
