export interface DataSyncExecution {
  id: string;
  created_at: string;
  updated_at: string;
  status: DataSyncExecutionStatus;
  exportCompletionTime?: string;
  importCompletionTime?: string;
  message: string;

  itemsImported?: number;
  itemsDropped?: number;
}

export enum DataSyncExecutionStatus {
  SYNC_STATUS_NOT_SET = 'SYNC_STATUS_NOT_SET',
  PENDING = 'PENDING',
  EXPORT_REQUESTED = 'EXPORT_REQUESTED',
  EXPORT_READY = 'EXPORT_READY',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}
