import React from 'react';
import styles from './MainContainer.module.sass';

interface MainContainerProps {
  title?: string;
  children: React.ReactNode;
  headerActions?: React.ReactNode;
}

const MainContainer: React.FC<MainContainerProps> = ({title, children, headerActions}) => {
  return (
    <>
      <div className={styles.header}>
        <div>{title}</div>
        {headerActions}
      </div>
      <div>
        {children}
      </div>
    </>
  );
};

export default MainContainer;
