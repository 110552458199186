import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import DataSyncList from './components/DataSyncList';
import { useHistory } from 'react-router-dom';
import MainContainer from './MainContainer';

export const DataSyncListPage = () => {
  const history = useHistory();

  const newSyncButton = (
    <Button
      intent={Intent.PRIMARY} text="New Sync"
      onClick={() => history.push('/data_syncs/new')}
    />
  );

  return (
    <MainContainer headerActions={newSyncButton}>
      <DataSyncList/>
    </MainContainer>
  );
};
