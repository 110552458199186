import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styles from './DataSyncFormSection.module.sass';
import { DropDown, Label, SelectItem } from '@zaiusinc/hera';

import DataSyncCGStore from '../../../../stores/DataSyncCGStore';
import { DataSyncDestination } from '../../../../types/DataSyncItem';
import { useObserver } from 'mobx-react-lite';
import Notification from '../Notification';
import { ValidateHandle } from './ValidateHandle';
import DataSyncProductsStore from '../../../../stores/DataSyncProductsStore';
import ContentGraphConfigSection from './ContentGraphConfigSection';

interface DataSyncFormDestinationProps {
  disabled?: boolean;
  onChange: (value: DataSyncDestination) => void;
  initialValue: DataSyncDestination;
  contentGraphStore: DataSyncCGStore | null;
}

const DataSyncFormDestination = forwardRef<ValidateHandle, DataSyncFormDestinationProps>(
  (props, ref) => {
    const {disabled, initialValue, onChange, contentGraphStore} = props;
    const [destination, setValue] = React.useState<DataSyncDestination>({...initialValue});
    const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
      product: false,
      instance: false,
      object: false,
    });

    const [dataSyncProductsStore, setDataSyncProductsStore] = useState<DataSyncProductsStore | null>(null);
    const contentGraphConfigRef = useRef<ValidateHandle>(null);

    useImperativeHandle(ref, () => ({
      validate: () => {
        setErrors({
          product: !destination.product,
          instance: !destination.instance.id,
          object: !destination.object_name,
        });

        let contentGraphConfigValid = true;
        if (destination.product === 'CGR') {
          contentGraphConfigValid = contentGraphConfigRef.current?.validate() ?? false;
        }

        return !!(destination.product && destination.instance.id && destination.object_name && contentGraphConfigValid);
      },
    }));

    useEffect(() => {
      setDataSyncProductsStore(new DataSyncProductsStore());
    }, []);

    useEffect(() => {
      if (destination?.instance?.id && contentGraphStore) {
        contentGraphStore.setInstanceId(destination.instance.id);
      }
    }, [destination?.instance?.id, contentGraphStore]);

    const productList = (): SelectItem[] => {
      return dataSyncProductsStore?.getProductList().map((product) => {
        return {
          text: product.text,
          value: product.value,
          icon: product.iconPath ?
            (<img height={30} width={30} src={product.iconPath} alt={product.text}/>) : undefined,
        };
      }) || [];
    };

    return useObserver(() => (
      <div className={styles.container}>
        <Label label="Destination" disabled={disabled} labelInfo="(OPTIMIZELY PRODUCT)"
               subLabel="Select an Optimizely product as destination to sync data from external app.">
          <DropDown
            disabled={disabled}
            initialContent="Select Product"
            items={productList()}
            loading={dataSyncProductsStore?.loading || false}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...destination, product: item.value};
              setValue(newData);
              onChange(newData);
              setErrors({...errors, product: false});
            }}
            value={destination.product}
            width={390}/>
          <Notification message="Please select a product" show={errors.product} type="error"/>
        </Label>

        <Label label="Instance" disabled={disabled}>
          <DropDown
            disabled={disabled}
            initialContent="Select Instance"
            items={dataSyncProductsStore?.getInstanceList(destination.product) || []}
            loading={dataSyncProductsStore?.loading || false}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...destination};
              newData.instance = {id: item.value, name: item.text};
              setValue(newData);
              onChange(newData);
              setErrors({...errors, instance: false});
            }}
            value={destination.instance.id}
            width={390}/>
          <Notification message="Please select an instance" show={errors.instance} type="error"/>
        </Label>

        <Label label="Object" disabled={disabled}>
          <DropDown
            filterable
            disabled={disabled}
            initialContent="Select Object"
            items={contentGraphStore?.getObjectList() || []}
            loading={contentGraphStore?.loading || false}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...destination, object_name: item.value};
              setValue(newData);
              onChange(newData);
              setErrors({...errors, object: false});
            }}
            value={destination.object_name}
            width={390}/>
          <Notification message="Please select an object" show={errors.object} type="error"/>
        </Label>

        {destination.product === 'CGR' &&
          <ContentGraphConfigSection
            initialValue={destination.config?.content_graph} ref={contentGraphConfigRef}
            onChange={(value) => {
              const newData = {...destination};
              newData.config = {content_graph: value};
              setValue(newData);
              onChange(newData);
            }}/>}
      </div>
    ));
  });

export default DataSyncFormDestination;
