import {Schema} from '@zaiusinc/app-forms-schema';
import {AppInstallation} from '../types/AppInstallation';
import {AppLogEntry} from '../types/AppLogEntry';
import {AppVersion} from '../types/AppVersion';
import {ZipApiError} from './ZipApiError';
import {ZipApiTimeoutError} from './ZipApiTimeoutError';
import {showToasts} from './toaster';
import {Intent} from '@blueprintjs/core';
import { DataSyncItem, ObjectDefinition, ProductInstances } from '../types/DataSyncItem';
import { DataSyncExecution } from '../types/DataSyncExecution';

const ZIP_API_URI: string = process.env.REACT_APP_ZIP_API_URI || (() => {
  const url = new URL(window.location.href);
  const stem = url.host.split('.').slice(1);
  if (stem[stem.length - 2] === 'zaius') {
    // Legacy quirks.
    stem.unshift('directory');
  }
  url.host = ['api'].concat(stem).join('.');
  return url.origin;
})();

export type ToastIntent = 'info' | 'success' | 'warning' | 'danger';

export type EnvelopedResponse<K extends string, T> = {
  [key in K]: T;
};

export interface ListAppResponse {
  results: AppVersion[];
}

export interface ListAppInstallationsResponse {
  installations: AppInstallation[];
}

export interface FetchAppResponse {
  app: AppVersion;
}

export interface FetchAppVersionResponse {
  app_version: AppVersion;
}

export interface InstallResponse {
  app_installation: AppInstallation;
}

export interface FetchFormDataResponse {
  form_data_json: Schema.FormData;
}

export interface SubmitFormDataResponse {
  form_data_json: Schema.FormData;
  response_json: {
    redirect?: string;
    redirectMode?: 'url' | 'settings';
    errors: {[field: string]: string[]};
    toasts: Array<{intent: ToastIntent, message: string}>;
  };
}

export interface ListFunctionsResponse {
  function_endpoints: {
    [name: string]: string;
  };
}

export interface AuthorizationRequestResponse {
  form_data_json: Schema.FormData;
  response_json: {
    redirect?: string;
    redirectMode?: 'url' | 'settings';
    errors: {[field: string]: string[]};
    toasts: Array<{intent: ToastIntent, message: string}>;
  };
}

export interface CanUninstallResponse {
  message?: string;
  uninstallable: boolean;
}

export interface ListAppLogEntriesResponse {
  log_entry: AppLogEntry[];
  cursor_token: string;
}

export interface DataSyncResponse {
  dataSync: DataSyncItem;
}

export interface ListDataSyncsResponse {
  dataSyncItems: DataSyncItem[];
}

export interface ListDataSyncExecutionResponse {
  executions: DataSyncExecution[];
}

export abstract class ZipApi {

  public static async listApps(trackerId?: string): Promise<ListAppResponse> {
    const queryParams = trackerId === undefined ? '' : `?tracker_id=${trackerId}`;
    const response = await this.performGet(`${ZIP_API_URI}/v1/apps${queryParams}`);
    return await response.json();
  }

  public static async listAppInstallations(trackerId: string): Promise<ListAppInstallationsResponse> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/accounts/${trackerId}/installs`);
    return await response.json();
  }

  public static async fetchApp(appId: string): Promise<FetchAppResponse> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/apps/${appId}`);
    return await response.json();
  }

  public static async fetchAppVersion(appId: string, version: string): Promise<FetchAppVersionResponse> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/apps/${appId}/versions/${version}`);
    return await response.json();
  }

  public static async install(trackerId: string, appId: string, version?: string): Promise<InstallResponse> {
    const response = await this.performPost(
      `${ZIP_API_URI}/v1/accounts/${trackerId}/installs`,
      JSON.stringify({app_id: appId, version})
    );
    return await response.json();
  }

  public static async canUninstall(installId: number): Promise<CanUninstallResponse> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/installs/${installId}/canUninstall`);
    return await response.json();
  }

  public static async uninstall(trackerId: string, appId: string): Promise<InstallResponse> {
    const response = await this.performRequest(
      'DELETE',
      `${ZIP_API_URI}/v1/accounts/${trackerId}/installs/${appId}`
    );
    return await response.json();
  }

  public static async createDataSync(dataSync: DataSyncItem): Promise<DataSyncResponse> {
    const response = await this.performPost(`${ZIP_API_URI}/v1/data-syncs`, JSON.stringify(dataSync));
    return await response.json();
  }

  public static async updateDataSync(dataSync: DataSyncItem): Promise<DataSyncResponse> {
    const response = await this.performRequest(
      'PUT',
      `${ZIP_API_URI}/v1/data-syncs/${dataSync.id}`,
      JSON.stringify(dataSync)
    );
    return await response.json();
  }

  public static async getDataSync(dataSyncId: string): Promise<DataSyncResponse> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/data-syncs/${dataSyncId}`);
    return await response.json();
  }

  public static async listDataSyncs(offset: number, limit: number, trackerId?: string): Promise<ListDataSyncsResponse> {
    const params = new URLSearchParams({
      'sort.field': 'createdAt',
      'sort.direction': '1',
      'offset': offset.toString(),
      'limit': limit.toString(),
    });

    if (trackerId) {
      params.append('tracker_id', trackerId);
    }

    const queryString = params.toString();
    const response = await this.performGet(`${ZIP_API_URI}/v1/data-syncs?${queryString}`);
    return await response.json();
  }

  public static async enableDataSync(dataSyncId: string) {
    const response = await this.performPatch(`${ZIP_API_URI}/v1/data-syncs/${dataSyncId}/enable`);
    return response.ok;
  }

  public static async disableDataSync(dataSyncId: string) {
    const response = await this.performPatch(`${ZIP_API_URI}/v1/data-syncs/${dataSyncId}/disable`);
    return response.ok;
  }

  public static async deleteDataSync(dataSyncId: string) {
    const response = await this.performRequest('DELETE', `${ZIP_API_URI}/v1/data-syncs/${dataSyncId}`);
    return response.ok;
  }

  public static async listDataSyncExecutions(dataSyncId: string, offset: number, limit: number):
    Promise<ListDataSyncExecutionResponse> {
    const params = new URLSearchParams({
      'offset': offset.toString(),
      'limit': limit.toString(),
      'sort.field': 'createdAt',
      'sort.direction': '1',
    });

    const queryString = params.toString();
    const response = await this.performGet(`${ZIP_API_URI}/v1/data-syncs/${dataSyncId}/executions?${queryString}`);
    return await response.json();
  }

  public static async listProductInstances(trackerId: string): Promise<ProductInstances[]> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/accounts/${trackerId}/product_instances`);
    const body =  await response.json() as EnvelopedResponse<'instances', ProductInstances[]>;
    if (body.instances) {
      return body.instances;
    } else {
      return [];
    }
  }

  public static async listODPSchemas(trackerId: string): Promise<ObjectDefinition[]> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/odp/schemas?tracker_id=${trackerId}`);
    const body =  await response.json() as EnvelopedResponse<'schemas', ObjectDefinition[]>;
    if (body.schemas) {
      return body.schemas;
    } else {
      return [];
    }
  }

  public static async listCGSchemas(trackerId: string, instanceId: string): Promise<ObjectDefinition[]> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/cg/schemas?tracker_id=${trackerId}&instance_id=${instanceId}`);
    const body =  await response.json() as EnvelopedResponse<'schemas', ObjectDefinition[]>;
    if (body.schemas) {
      return body.schemas;
    } else {
      return [];
    }
  }

  public static async fetchFormData(installId: number): Promise<FetchFormDataResponse> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/installs/${installId}/settings`);
    const data = await response.json();
    return {
      form_data_json: JSON.parse(data.form_data_json)
    };
  }

  public static async submitFormData(installId: number, jsonPayload: string): Promise<SubmitFormDataResponse> {
    const response = await this.performPost(`${ZIP_API_URI}/v1/installs/${installId}/settings`, jsonPayload);
    const data = await response.json();
    return {
      form_data_json: JSON.parse(data.form_data_json),
      response_json: JSON.parse(data.response_json)
    };
  }

  public static async listFunctions(installId: number): Promise<ListFunctionsResponse['function_endpoints']> {
    const response = await this.performGet(`${ZIP_API_URI}/v1/installs/${installId}/functions`);
    return (await response.json() as ListFunctionsResponse).function_endpoints;
  }

  public static async listAppLogEntries(
    jsonPayload: string,
    attemptsLeft: number = 3
  ): Promise<ListAppLogEntriesResponse> {
    try {
      const response = await this.performPost(`${ZIP_API_URI}/v1/logging/search`, jsonPayload);
      return await response.json();
    } catch (e) {
      if (e instanceof ZipApiError && attemptsLeft > 0) {
        await new Promise( (resolve) => setTimeout(resolve, 100));
        return this.listAppLogEntries(jsonPayload, attemptsLeft - 1);
      }
      if (e instanceof ZipApiTimeoutError) {
        showToasts([
          {
            intent: Intent.DANGER,
            message: 'Timeout while fetching logs. Please try again. Consider narrowing down the date range.'
          }
          ]);
        return {
            log_entry: [],
            cursor_token: ''
        };
      }

      throw e;
    }
  }

  public static async authorizationRequest(installId: number, jsonPayload: string):
    Promise<AuthorizationRequestResponse> {
    const response = await this.performPost(
      `${ZIP_API_URI}/v1/installs/${installId}/authorization/request`, jsonPayload
    );
    const data = await response.json();
    return {
      form_data_json: JSON.parse(data.form_data_json),
      response_json: JSON.parse(data.response_json)
    };
  }

  public static async triggerSync(dataSyncId: string): Promise<boolean> {
    const response = await this.performPatch(`${ZIP_API_URI}/v1/data-syncs/${dataSyncId}/trigger`);
    return response.ok;
  }

  private static async performGet(url: string) {
    const response = await fetch(url, {credentials: 'include'});
    if (!response.ok) {
      throw new ZipApiError(response);
    }
    return response;
  }

  private static async performPost(url: string, body: string) {
    return this.performRequest('POST', url, body);
  }

  private static async performPatch(url: string) {
    return this.performRequest('PATCH', url);
  }

  private static async performRequest(method: string, url: string, body?: string) {
    let response;
    try {
      response = await fetch(url, {
        method,
        body,
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });
    } catch (e) {
      if (e instanceof TypeError) {
        throw new ZipApiTimeoutError();
      }

      throw e;
    }

    if (!response.ok) {
      throw new ZipApiError(response);
    }
    return response;
  }
}
