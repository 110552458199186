export const cgStatuses = [
  {text: 'Draft', value: 'DRAFT'},
  {text: 'Ready', value: 'READY'},
  {text: 'In Review', value: 'IN_REVIEW'},
  {text: 'Rejected', value: 'REJECTED'},
  {text: 'Scheduled', value: 'SCHEDULED'},
  {text: 'Published', value: 'PUBLISHED'},
  {text: 'Previously Published', value: 'PREVIOUSLY_PUBLISHED'}
];

export const cgLanguages = [
  {text: 'Neutral', value: 'CG_LANGUAGE_NEUTRAL'},
  {text: 'Arabic', value: 'CG_LANGUAGE_ARABIC'},
  {text: 'Bulgarian', value: 'CG_LANGUAGE_BULGARIAN'},
  {text: 'Brazilian', value: 'CG_LANGUAGE_BRAZILIAN'},
  {text: 'Catalan', value: 'CG_LANGUAGE_CATALAN'},
  {text: 'CJK', value: 'CG_LANGUAGE_CJK'},
  {text: 'German', value: 'CG_LANGUAGE_GERMAN'},
  {text: 'Greek', value: 'CG_LANGUAGE_GREEK'},
  {text: 'English', value: 'CG_LANGUAGE_ENGLISH'},
  {text: 'Spanish', value: 'CG_LANGUAGE_SPANISH'},
  {text: 'Basque', value: 'CG_LANGUAGE_BASQUE'},
  {text: 'Farsi', value: 'CG_LANGUAGE_FARSI'},
  {text: 'Finnish', value: 'CG_LANGUAGE_FINNISH'},
  {text: 'French', value: 'CG_LANGUAGE_FRENCH'},
  {text: 'Galician', value: 'CG_LANGUAGE_GALICIAN'},
  {text: 'Hindi', value: 'CG_LANGUAGE_HINDI'},
  {text: 'Hungarian', value: 'CG_LANGUAGE_HUNGARIAN'},
  {text: 'Armenian', value: 'CG_LANGUAGE_ARMENIAN'},
  {text: 'Indonesian', value: 'CG_LANGUAGE_INDONESIAN'},
  {text: 'Italian', value: 'CG_LANGUAGE_ITALIAN'},
  {text: 'Japanese', value: 'CG_LANGUAGE_JAPANESE'},
  {text: 'Korean', value: 'CG_LANGUAGE_KOREAN'},
  {text: 'Kurdish', value: 'CG_LANGUAGE_KURDISH'},
  {text: 'Latvian', value: 'CG_LANGUAGE_LATVIAN'},
  {text: 'Dutch', value: 'CG_LANGUAGE_DUTCH'},
  {text: 'Norwegian', value: 'CG_LANGUAGE_NORWEGIAN'},
  {text: 'Polish', value: 'CG_LANGUAGE_POLISH'},
  {text: 'Romanian', value: 'CG_LANGUAGE_ROMANIAN'},
  {text: 'Russian', value: 'CG_LANGUAGE_RUSSIAN'},
  {text: 'Swedish', value: 'CG_LANGUAGE_SWEDISH'},
  {text: 'Thai', value: 'CG_LANGUAGE_THAI'},
  {text: 'Turkish', value: 'CG_LANGUAGE_TURKISH'},
  {text: 'Ukrainian', value: 'CG_LANGUAGE_UKRAINIAN'},
  {text: 'Chinese', value: 'CG_LANGUAGE_CHINESE'},
];
