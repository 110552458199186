import {Button, ButtonGroup, Intent, Menu, MenuItem, Popover, Position} from '@blueprintjs/core';
import {DropDown, SearchInput} from '@zaiusinc/hera';
import {subDays, subHours, subMinutes} from 'date-fns';
import React, {useCallback, useState} from 'react';
import {DATE_RANGE_SHORTCUTS, LogDateRangePicker} from './LogDateRangePicker';

import {DateRange} from '@blueprintjs/datetime';
import {useObserver} from 'mobx-react-lite';
import {AppDetailStore} from '../../../stores/AppDetailStore';
import styles from './AppLogs.module.sass';
import {SegmentAnalytics} from '../../../lib/SegmentAnalytics';

interface Props {
  detailStore: AppDetailStore;
}

export const AppLogFilters = ({ detailStore }: Props) => {
  const logLevels = [
    { text: 'All', value: 'all', index: 0 },
    { text: 'Debug', value: 'debug', index: 1 },
    { text: 'Info', value: 'info', index: 2 },
    { text: 'Warn', value: 'warn', index: 3 },
    { text: 'Error', value: 'error', index: 4 }
  ];

  const onSearchChange = (search: string) => {
    detailStore.setLogFilter({ ...detailStore.logFilter, search });
  };

  const [logLevel, setLogLevel] = React.useState(logLevels[0]);
  const onLogLevelSelect = (level: { text: string; value: string; index: number }) => {
    if (level.value === 'all') {
      delete detailStore.logFilter.level;
      detailStore.fetchLogs();
    } else {
      detailStore.setLogFilter({ ...detailStore.logFilter, level: level.value });
    }
    setLogLevel(level);

    const {pathname} = window.location;
    SegmentAnalytics.track(`App: ${pathname} | Troubleshoot log level: ${level.text}`, 'Troubleshoot');
  };

  const initialRange: DateRange = [detailStore.logFilter.startDate ?? null, detailStore.logFilter.endDate ?? null];
  const [dateRange, setDateRange] = useState(initialRange);
  const [dateShortcut, setDateShortcut] = useState(DATE_RANGE_SHORTCUTS.PAST_HOUR);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const closeDatePicker = useCallback(() => {
    setIsDatePickerOpen(false);
  }, []);
  const openDatePicker = useCallback(() => {
    setIsDatePickerOpen(true);
  }, []);

  const handleDateChange = (range: DateRange | string) => {
    let startDate;
    let endDate: Date | undefined = new Date();
    if (typeof range === 'string') {
      setDateShortcut(range);
      setDateRange([null, null]);
      switch (range) {
        case DATE_RANGE_SHORTCUTS.PAST_15_MIN:
          startDate = subMinutes(endDate, 15);
          break;
        case DATE_RANGE_SHORTCUTS.PAST_30_MIN:
          startDate = subMinutes(endDate, 30);
          break;
        case DATE_RANGE_SHORTCUTS.PAST_HOUR:
          startDate = subHours(endDate, 1);
          break;
        case DATE_RANGE_SHORTCUTS.PAST_3_HOURS:
          startDate = subHours(endDate, 3);
          break;
        case DATE_RANGE_SHORTCUTS.PAST_6_HOURS:
          startDate = subHours(endDate, 6);
          break;
        case DATE_RANGE_SHORTCUTS.PAST_12_HOURS:
          startDate = subHours(endDate, 12);
          break;
        case DATE_RANGE_SHORTCUTS.YESTERDAY:
          startDate = subDays(endDate, 1);
          break;
        default:
          startDate = subDays(endDate, 7);
          break;
      }
    } else {
      setDateShortcut('');
      setDateRange(range);
      startDate = range[0];
      endDate = range[1] ?? undefined;
    }
    if (startDate && endDate) {
      detailStore.setLogFilter({ ...detailStore.logFilter, startDate, endDate });
    }
  };

  const handleRefresh = () => {
    if (dateShortcut) {
      handleDateChange(dateShortcut);
    } else {
      detailStore.fetchLogs();
    }
  };

  const downloadLogs = () => {
    const element = document.createElement('a');
    const encodedAppLogs = encodeURIComponent(detailStore.appLogsContent);
    const fileName = `${detailStore.appId}_${detailStore.version}.log`;
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodedAppLogs);
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return useObserver(() => {
    const downloadDisabled = (!detailStore.appLogs || detailStore.appLogs.length === 0 ||
      !detailStore.moreLogLoaded || !detailStore.logLoaded);

    return (
      <div className={styles.headers}>
        <div className={styles['header-left']}>
          <SearchInput
            changeDebounce={500}
            searchTerm={detailStore.logFilter.search}
            onChange={onSearchChange}
            placeholder="Search logs" />
          <div className={styles.header}>
            <span className={styles.filter}>Log Level</span>
            <DropDown items={logLevels} initialContent="All" value={logLevel} onItemSelect={onLogLevelSelect} />
          </div>
        </div>
        <div className={styles['header-right']}>
          <div className={styles.header}>
            <span className={styles.filter}>Date Range</span>
            <LogDateRangePicker
              closePopover={closeDatePicker}
              isOpen={isDatePickerOpen}
              handleDateChange={handleDateChange}
              dateRange={dateRange}
              openPopover={openDatePicker}
            />
          </div>
          <div className={styles['action-buttons']}>
            <Button intent={Intent.PRIMARY} rightIcon="refresh" text="Refresh" onClick={handleRefresh} />
            <Button
              className={styles['btn-disabled']}
              intent={downloadDisabled ? Intent.NONE : Intent.PRIMARY}
              disabled={downloadDisabled}
              rightIcon="download" text="Download" onClick={downloadLogs} />
          </div>
          <ButtonGroup minimal className={styles['menu-buttons']}>
            <Popover
              popoverClassName={styles['menu-popover']}
              position={Position.BOTTOM_RIGHT}
              minimal
              content={
                  <Menu>
                    <MenuItem text="Refresh" icon="refresh" onClick={handleRefresh} />
                    <MenuItem disabled={downloadDisabled} text="Download" icon="download" onClick={downloadLogs} />
                  </Menu>
                }
              >
              <Button minimal icon="more" />
            </Popover>
          </ButtonGroup>
        </div>
      </div>
    );
  });
};
