import React, { forwardRef, useImperativeHandle } from 'react';

import { InputGroup } from '@blueprintjs/core';
import styles from './DataSyncFormSection.module.sass';
import Notification from '../Notification';
import { Label } from '@zaiusinc/hera';
import { ValidateHandle } from './ValidateHandle';

export interface DataSyncConfigSectionProps {
  value: any;
  onChange: (value: any) => void;
}

const DataSyncConfigSection = forwardRef<ValidateHandle, DataSyncConfigSectionProps>((props, ref) => {
  const { value, onChange } = props;
  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
    name: false,
  });

  useImperativeHandle(ref, () => ({
    validate: () => {
      setErrors({...errors, name: !value.name});
      return !!value.name;
    },
  }));

  return (
    <Label label="Sync Name">
      <InputGroup
        className={styles.syncName} value={value.name} placeholder="Name"
        onChange={(e) => {
          onChange({name: e.target.value});
          setErrors({...errors, name: false});
        }}
      />
      <Notification message="Please enter a name" show={errors.name} type="error"/>
    </Label>
  );
});

export default DataSyncConfigSection;
